import { ProjectStatuses } from "@/components/constants.js"
import { ProjectStatus } from "@evercam/shared/types/project"

const intervals = (isValidatedDay = false) => [
  {
    value: "less_2_days",
    name: `Less than 2 ${isValidatedDay ? "unprocessed days" : "days ago"}`,
    filter: (distance) => distance < 2,
  },
  {
    value: "between_2_and_5_days",
    name: `Between 2 and 5 ${isValidatedDay ? "unprocessed days" : "days ago"}`,
    filter: (distance) => distance >= 2 && distance < 5,
  },
  {
    value: "5_days_or_more",
    name: `5 ${isValidatedDay ? "unprocessed days" : "days ago"} or more`,
    filter: (distance) => distance >= 5,
  },
]

const isFiltered = (distance, search) => {
  const interval = intervals().find((item) => item.value === search)
  if (interval) {
    return interval.filter(distance)
  }

  return true
}

export default (self) => {
  return {
    name: {
      component: "TextFieldSearchFilter",
      offline: true,
    },
    exid: {
      component: "TextFieldSearchFilter",
      offline: true,
    },
    cameras: {
      component: "TextFieldSearchFilter",
      offline: true,
      customFilter(item, fieldName, search) {
        return !!item[fieldName]?.find((camera) =>
          camera?.name?.toLowerCase()?.includes(search?.toLowerCase())
        )
      },
    },
    status: {
      component: "SelectSearchFilter",
      offline: true,
      attributes: {
        items: [{ value: "", name: "All" }, ...ProjectStatuses],
        value: ProjectStatus.InProgress,
      },
    },
    lastDetection: {
      component: "SelectSearchFilter",
      offline: true,
      attributes: {
        items: [{ value: "", name: "All" }, ...intervals(true)],
        value: "",
      },
      customFilter(item, _, search) {
        for (const camera of item.cameras) {
          const lastDetectedEvent = self.$moment(camera?.lastDetectedEvent)
          const lastProcessedDay = self.$moment(camera?.lastProcessedDay)
          const distance = lastProcessedDay.diff(lastDetectedEvent, "days")
          if (isFiltered(distance, search)) {
            return true
          }
        }

        return false
      },
    },
  }
}
